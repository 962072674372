import { createSelector } from "@ngrx/store";
import { AppState } from "..";
import { LocalizationState } from "../actions/localization.actions";

export const selectLocalizationState = (state: AppState) => state.localization;

export const selectLocalization = createSelector(
    selectLocalizationState,
    (state: LocalizationState) => state.localization
)

export const selectLocalizationList = createSelector(
    selectLocalizationState,
    (state: LocalizationState) => state.localizationList
);

export const selectLocale = createSelector(
    selectLocalizationState,
    (state: LocalizationState) => state.localization.language
)

export const selectCountries = createSelector(
    selectLocalizationState,
    (state: LocalizationState) => state.countries
)

export const selectAvailableCountries = createSelector(
    selectLocalizationState,
    (state: LocalizationState) => state.countries.filter(item => item.is_enabled)
)

export const selectDefaultCountries = createSelector(
    selectLocalizationState,
    (state: LocalizationState) => state.countries.find(item => item.iso2 === 'FR')
)