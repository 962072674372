import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { enGB, fr } from 'date-fns/locale';
import { DateFnsConfigurationService } from 'ngx-date-fns';
import { Observable } from 'rxjs';
import { setLocalization, setLocalizationUser } from 'src/app/store/actions/localization.actions';
import { selectConnectedUser } from 'src/app/store/selectors/auth.selectors';
import { selectLocalization, selectLocalizationList } from 'src/app/store/selectors/localization.selectors';
import { mergeMap, take, filter, map } from 'rxjs/operators';
import { TranslocoService, getBrowserLang, TRANSLOCO_LOADING_TEMPLATE } from '@ngneat/transloco';
import type { User } from '../../types/auth';
import { AuthService } from 'src/app/services/auth.service';
import { deleteAccount, getUserSuccess, logout } from 'src/app/store/actions/auth.actions';

@Component({
  selector: 'kabin-localization',
  templateUrl: './localization.component.html',
  styleUrls: ['./localization.component.scss'],
})
export class LocalizationComponent implements OnInit {

  languages$: Observable<Array<string>>;
  locale: string;
  user: User;

  constructor(
    public store: Store,
    public config: DateFnsConfigurationService,
    public translocoService: TranslocoService,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.languages$ = this.store.select(selectLocalizationList);
    this.store.select(selectLocalization).subscribe(locale => {
      this.switchDateFNSConfig(locale)
      this.locale = locale;
    })
    this.store.select(selectConnectedUser).subscribe((user) => {
      this.user = user;
      if (user) {
        this.translocoService.setActiveLang(user.localization);
        this.switchDateFNSConfig(user.localization)
        this.locale = user.localization
        this.store.dispatch(setLocalization({lang: user.localization}));
      }
    })
  }

  switchDateFNSConfig(lang: string) {
    switch (lang) {
      case "en-GB":
        this.config.setLocale(enGB);
        break;
      case "fr-FR":
        this.config.setLocale(fr);
        break;
      default: this.config.setLocale(fr)
    }
  }

  setLanguage(lang: CustomEvent) {
    if (this.user) {
      this.authService.updateProfile({
        localization: lang.detail.value,
      }).subscribe((user : User) => {
        this.store.dispatch(getUserSuccess({user}));
      }, err => {
        console.log({submitFormErr: err})
      })
    } else {
      this.translocoService.setActiveLang(lang.detail.value);
    }
  }
}
