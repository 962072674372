import { createSelector } from "@ngrx/store";
import { AppState } from "..";
import { MeState } from "../reducers/me.reducer";
import { BillingAddress, PaymentMethod, Coupon } from "src/app/types/payment";

export const selectMeState = (state: AppState) => state.me;

export const selectPaymentMethods = createSelector(
  selectMeState,
  (state: MeState) => state.paymentMethods
)

export const selectDefaultPaymentMethod = createSelector(
  selectPaymentMethods,
  (state: PaymentMethod[]) => state.find((pm: PaymentMethod) => pm.is_default) ?? state.length ? state[0] : null
)

export const selectBillingAddresses = createSelector(
  selectMeState,
  (state: MeState) => state.billingAddresses
)

export const selectDefaultBillingAddress = createSelector(
  selectBillingAddresses,
  (state: BillingAddress[]) => state.find((address: BillingAddress) => address.is_default) ?? state.length ? state[0] : null
)

export const selectDiscounts = createSelector(
  selectMeState,
  (state: MeState) => state.coupons
)

export const selectDefaultDiscount = createSelector(
  selectDiscounts,
  (state: Coupon[]) => state && state.length ? state[0] : null,
)