import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'kabin-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss'],
})
export class PasswordStrengthComponent implements OnInit {

  @Input() password: string
  @Input() color: string = 'dark'

  passwordStrenghText$ = new Observable<any>;
  passwordMeter$ = new BehaviorSubject<number>(0);
  public passwordStrengthKeys: string[] = [
    'empty',
    'low',
    'medium',
    'strong',
  ];

  constructor() {
    this.passwordStrenghText$ = this.passwordMeter$.pipe(
      distinctUntilChanged(),
      map((passwordMeter) => !passwordMeter ? this.passwordStrengthKeys[0] : this.passwordStrengthKeys[passwordMeter/(1/3)])
    );
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    let currentPassword = changes.password.currentValue || ''
    let lengthRegex = /^(?=.{5,}).*$/
    let majRegex = /^(?=.*[A-Z]).*$/
    let numberRegex = /^(?=.*[0-9]).*$/
    let specialRegex = /^(?=.*[@#,$*%\-\._:\"!?\'(){}[\];^&+=]).*$/
    let passwordMeter = 0;

    if (currentPassword.length) {
      if (currentPassword.match(lengthRegex)) {
        if (currentPassword.match(majRegex)) {
          passwordMeter++;
        }
        if (currentPassword.match(numberRegex)) {
          passwordMeter++;
        }
        if (currentPassword.match(specialRegex)) {
          passwordMeter++;
        }
        if (passwordMeter === 0) {
          passwordMeter = 1;
        }
      } else {
        console.log('no length')
        passwordMeter = 1;
      }
    } else {
      passwordMeter = 0;
    }

    this.passwordMeter$.next((1/3) * passwordMeter);
  }
}
