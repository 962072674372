import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AlertController, ModalController, NavController } from '@ionic/angular';

import { User } from 'src/app/types/auth';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, finalize, tap } from 'rxjs/operators';
import { Confirm } from 'src/app/types/global';
import { Store } from '@ngrx/store';
import { deleteAccount, getUserSuccess, logout } from 'src/app/store/actions/auth.actions';
import { selectConnectedUser, selectConnectionStatus } from 'src/app/store/selectors/auth.selectors';
import { AuthService } from 'src/app/services/auth.service';
import { registerSelectCountry } from 'src/app/store/actions/auth.actions';
import { selectAuthRegisterCountry } from 'src/app/store/selectors/auth.selectors';
import type { Country } from 'src/app/types/global';
import { selectLocalization, selectCountries } from 'src/app/store/selectors/localization.selectors';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { PopoverController } from '@ionic/angular';
import { PmrPopoverComponent } from 'src/app/components/popovers/pmr-popover/pmr-popover.component';
import { EmailPopoverComponent } from 'src/app/components/popovers/email-popover/email-popover.component';

@Component({
  selector: 'kabin-account-general-information',
  templateUrl: './general-information.component.html',
  styleUrls: ['./general-information.component.scss'],
})
export class AccountGeneralInformationComponent implements OnInit {
  
  @Output() setAccountStep = new EventEmitter<Number>();
  public user: User;
  public userHasCMI: Boolean = false;
  public updateCMILoading: Boolean = false;
  public readOnly: Boolean = true;
  public formProfile: UntypedFormGroup = this.formBuilder.group({
    firstname: ['', [
      Validators.required,
      Validators.pattern(/^[a-zA-Z-\s\u00C0-\u00FF]*$/),
      Validators.maxLength(20)
    ]],
    lastname: ['', [
      Validators.required,
      Validators.pattern(/^[a-zA-Z-\s\u00C0-\u00FF]*$/),
      Validators.maxLength(20)
    ]],
    phone: ['', []],
    email: ['', [
      Validators.required, Validators.email
    ]]
  });
  public formProfileSubmitted: Boolean = false;
  public alerts = null;
  public documentsVerification: any = {
    icon: 'close-circle-outline',
    msg: ''
  }
  public alert: string;
  public confirm: Confirm;

  country$: Observable<Country>;
  countries$: Observable<Country[]>;

  public textHtml$: Observable<any>; 

  constructor(
    private router: Router,
    private authService: AuthService,
    public formBuilder: UntypedFormBuilder,
    private store: Store,
    public modalCtrl: ModalController,
    public popoverCtrl: PopoverController,
  ) {
    this.store.select(selectConnectionStatus).subscribe((isConnected: boolean) => {
      if (this.router.url === '/register') {
        this.router.navigate(['register/welcome']);
      }
    })
    this.store.select(selectLocalization)
    this.country$ = this.store.select(selectAuthRegisterCountry);
    this.store.select(selectConnectedUser).pipe(
      filter((user: User) => user !== null)
    ).subscribe((user: User) => {
      this.user = user;
      this.userHasCMI = user.has_cmi;
      this.restoreForm();
      if (user.is_verified) {
        this.documentsVerification = {
          icon: 'checkmark-outline',
          msg: 'Vérifiés'
        };
      } else {
        this.documentsVerification = {
          icon: 'close-circle-outline',
          msg: 'Non vérifié'
        };
      }
    })
    this.countries$ = store.select(selectCountries);
  }

  selectCountry({target}) {
    this.countries$.subscribe(countries => {
      this.store.dispatch(registerSelectCountry({ country: countries.find((country: Country) => country.iso2 === target.value) }))
    });
  }

  getFormErrors() {
    return this.formProfile.controls
  }

  editProfile() {
    this.readOnly = !this.readOnly
    if (this.readOnly) {
      this.confirm = null
    } else {
      this.confirm = {
        title: 'account.modal.cancel.title',
        description: 'account.modal.cancel.description',
        icon: `trash-outline`
      }
    }
  }

  cancel() {
    this.readOnly = true
    this.confirm = null
    this.restoreForm()
  }

  restoreForm() {
    this.formProfile.patchValue({
      firstname: this.user.firstname,
      lastname: this.user.lastname,
      phone: this.user.phone ? (this.user.phone.indicator + this.user.phone.phone) : '',
      email: this.user.email
    });
  }

  submitForm() {
    const data = this.formProfile.value;
    this.authService.updateProfile(data).subscribe((user : User) => {
      this.store.dispatch(getUserSuccess({user}));
      this.readOnly = true;
      this.confirm = null
    }, err => {
      console.log({submitFormErr: err})
    })
  }

  openInBrowser(link: string) {
    window.open(link, '_system');
  }

  openPasswordModal() {
    this.setAccountStep.emit(2);
  }

  logout() {
    this.store.dispatch(logout());
  }

  ngOnInit() {
  }

  deleteAccount() {
    this.setAccountStep.emit(3)
  }

  async showPmrPopover (event) {
    const popover = await this.popoverCtrl.create({
      component: PmrPopoverComponent,
      cssClass: `popover-fakemodal`,
      event,
    })
    return popover.present();
  }

  async showEmailPopover (event) {
    const popover = await this.popoverCtrl.create({
      component: EmailPopoverComponent,
      cssClass: `popover-fakemodal`,
      event,
    })
    return popover.present();
  }
}
