export const environment = {
  production: true,
  debug: false,
  apiUrl: 'https://api.kabin.fr/api/',
  apiKey: 'AIzaSyDeOs20CQPhZYjn2VfYn2WdhDHKUsYUcgM',
  stripePublicKey: 'pk_live_51HyKAtC09bJzn8ZVkFAeFALZamvI2wnCrNWpRqfUtkOo93RG9yN8eCEjpWGiViVvfxbJT0w0oDEyyJPXBqckJRqb00ydWMlayc',
  pusher: {
    host: 'soketi.webrocks.net',
    port: 443,
    scheme: 'https',
    app_id: 'kabin',
    key: 'b90e8a0c-e604-40e6-917e-fd3e336aec92',
    secret: 'ec02fc4f-0a23-4db3-b77a-59d0bf6dac3a',
    cluster: 'mt1'
  },
  aws: {
    ACCESS_KEY_ID: 'SCW66YTDN4Y9WD8V29S3',
    SECRET_ACCESS_KEY: 'f9b06628-17ee-4a6a-8590-0e6f6a4a212e',
    DEFAULT_REGION: 'fr-par',
    BUCKET: 'kabin',
    URL: 'https://kabin.s3.fr-par.scw.cloud',
    ENDPOINT: 'https://s3.fr-par.scw.cloud'
  }
};
