// Modules
import { Component, Input, ViewChild, OnInit, ElementRef, Renderer2, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { chunk, isEmpty, head, isEqual, isNull, isNil } from 'lodash';

// Interfaces
import { Pod } from 'src/app/types/pod';
import { Coupon, BillingAddress, PaymentMethod } from 'src/app/types/payment';
import { BookingSelection, ParsedTime } from 'src/app/types/booking';
import { Hours } from 'src/app/types/global';

// Services
import { User } from 'src/app/types/auth';
import {
  Card,
  loadStripe,
  Stripe,
} from '@stripe/stripe-js';
import { environment } from 'src/environments/environment';
import { default as months } from 'src/app/utils/months.json';

// Pages
import { Store } from '@ngrx/store';
import { selectConnectedUser, selectConnectionStatus, selectIsGuest } from 'src/app/store/selectors/auth.selectors';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { filter, map } from 'rxjs/operators';
import { clearBookingState, createBooking, setCurrent, setPod } from 'src/app/store/actions/booking.actions';
import { selectBookingError, selectEnd, selectIsLoading, selectSelectedPaymentMethod, selectStart } from 'src/app/store/selectors/booking.selectors';
import { Browser } from '@capacitor/browser';
import { DateFnsConfigurationService } from 'ngx-date-fns';
import {
  set,
  format,
  differenceInMinutes,
  getMonth,
  endOfMonth,
  isAfter,
  isBefore,
  isSameDay,
  isThisMonth,
  isThisYear,
  subDays
} from 'date-fns';

import { PusherService } from 'src/app/services/pusher.service';
import { clearBookingError } from 'src/app/store/actions/ride.actions';
import { selectHasRunningBooking } from 'src/app/store/selectors/ride.selectors';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';

@Component({
  selector: 'kabin-booking',
  templateUrl: './booking.page.html',
  styleUrls: ['./booking.page.scss'],
})
export class BookingPage implements OnInit, OnDestroy {

  @Input() pod: Pod;
  @Input() modal: any;

  @ViewChild('bookingContainer', { static: false }) bookingContainer: ElementRef;

  isGuest$: Observable<boolean>;
  today = new Date();
  nbOfQuarters = 1;
  dragPosition = { x: 0, y: 0 };
  userAcceptedTerms = false;
  stripe: Stripe;
  x = 0;
  y = 0;
  startX = 0;
  startY = 0;
  direction = 'down';
  // submitLoader: boolean = false;
  user: User;
  userHasRequirement: boolean = true;
  bookingError$: Observable<HttpErrorResponse>;
  isConnected$: Observable<boolean>;
  selected: BookingSelection;
  selectedPaymentMethod: PaymentMethod;
  selectedBillingAddress: BillingAddress;
  selectedCoupon: Coupon = null;
  alert: string | boolean = false;
  bookingSuccess: boolean = false;
  isLoading$: Observable<boolean>
  marginTop: string | undefined = undefined;
  selection: { from: Date, to: Date }
  submitEnabled$: Observable<boolean>
  termsAccepted$ = new BehaviorSubject<boolean>(false)
  hasRunningBooking$: Observable<boolean>
  selectedPayment$: Observable<PaymentMethod>
  svgURL = environment.aws.URL

  constructor(
    private renderer: Renderer2,
    private router: Router,
    private modalCtrl: ModalController,
    private store: Store,
    private changeDetectorRef: ChangeDetectorRef,
    private config: DateFnsConfigurationService,
    private pusherService: PusherService,
  ) {
    this.isConnected$ = this.store.select(selectConnectionStatus);
    this.isGuest$ = this.store.select(selectIsGuest);
    this.isLoading$ = this.store.select(selectIsLoading)
    this.bookingError$ = this.store.select(selectBookingError);
    this.hasRunningBooking$ = this.store.select(selectHasRunningBooking);
    this.selectedPayment$ = this.store.select(selectSelectedPaymentMethod)
  }

  async ngOnInit() {
    if (Capacitor.isNativePlatform()) {
      StatusBar.setStyle({style: Style.Dark});
    }
    this.store.dispatch(setPod({ pod: this.pod }))
    this.store.select(selectConnectedUser).pipe(
      filter((user: User) => user !== null)
    ).subscribe((user: User) => {
      this.user = user;
      if (!user.is_ID_validated && this.pod.access_requirement === 's2') {
        this.alert = true;
        this.userHasRequirement = false;
      }
    });
    this.stripe = await loadStripe(environment.stripePublicKey);
    this.selected = {
      day: this.today,
      month: getMonth(this.today),
      from: null,
      to: null,
    };
    const channel = this.pusherService.subscribe(`pod.${this.pod.uuid}`)
    channel.bind('booking-extended', (x: any) => {
      // console.log('booking-extended', x)
    })
    this.submitEnabled$ = combineLatest([
      this.store.select(selectSelectedPaymentMethod).pipe(
        filter( (payment: PaymentMethod) => {
          if (payment) {
            return this.isAuthorized(payment)
          } else {
            return true
          }
        })
      ),
      this.store.select(selectStart),
      this.store.select(selectEnd),
      this.termsAccepted$,
    ]).pipe(
      map(([pm, start, end, terms]: [PaymentMethod, Date, Date, boolean]) => {
        return ((!!pm && terms) || (this.pod.is_free)) && !!start && !!end
      })
    )
  }

  ngOnDestroy(): void {
    this.store.dispatch(clearBookingState())
  }

  async openInBrowser(url: string) {
    await Browser.open({ url });
  }

  isNumber(num: number) {
    return Number.isInteger(num)
  }

  userDismiss() {
    this.modalCtrl.dismiss({ dismissed: true });
  }

  toggleTerms() {
    this.termsAccepted$.next(!this.termsAccepted$.value)
  }

  isAuthorized(payment: PaymentMethod) {
    return ['valid', 'soon'].includes(this.checkExpiry(payment.card))
  }

  checkExpiry(card: any) {
    let lastDay = endOfMonth(new Date(card.exp_year, card.exp_month - 1))
    let currentEndOfMonth = endOfMonth(new Date())
    if (isAfter(lastDay, new Date())) {
      if (isThisYear(lastDay)) {
        if (isThisMonth(lastDay)) {
          if ( isBefore( subDays( currentEndOfMonth, 3), new Date()) || isSameDay(subDays( currentEndOfMonth, 3), new Date()) ) {
            return 'close'
          }
          if ( isBefore( subDays( currentEndOfMonth, 7), new Date()) || isSameDay(subDays( currentEndOfMonth, 7), new Date()) ) {
            return 'soon'
          }
        }
      }
      return 'valid'
    }
    return 'invalid'
  }

  /**
   * Close modal
   */
  dismiss() {
    this.modalCtrl.dismiss({ dismissed: true });
  }

  /**
   *
   * Close modal and reroute user
   */
  dismissAndReroute(route: string) {
    this.modalCtrl.dismiss({
      dismissed: true,
      reroute: route
    });
  }

  submitBooking(): void {

    // const payload: any = {
    //   booking_start_at: this.selection.from,
    //   booking_end_at: this.selection.to,
    //   pod: this.pod.uuid,
    // };

    // if (this.selectedPaymentMethod) {
    //   payload.payment_method_id = this.selectedPaymentMethod.id
    // }

    // if (this.selectedBillingAddress) {
    //   payload.billing_address_uuid = this.selectedBillingAddress.uuid
    // }

    // if (this.selectedCoupon && this.selectedCoupon.uuid) {
    //   payload.discount_uuid = this.selectedCoupon.uuid;
    // }

    this.store.dispatch(createBooking())
  }

  dragEnd(event) {
    const distance = event.distance.y;
    if (distance >= 200) {
      this.dismiss();
    } else {
      // reset to default position
      /*
      const bookingContainer = this.bookingContainer.nativeElement;
      this.renderer.setStyle(
        bookingContainer,
        'transform',
        'translate3d(0px, 0, 0px)');
      */
      const element = event.source.getRootElement();
      element.style.transform = 'translate3d(0px, 0px, 0px)';
      //  element.setStyle('transform', );
    }
  }

  onPanUp(event: any): void {
    this.direction = 'up';
  }

  onPanDown(event: any): void {
    this.direction = 'down';
  }

  onPan(event: any): void {
    const bookingContainer = this.bookingContainer.nativeElement;
    event.preventDefault();
    if (event.deltaY <= 0) { // max top
      this.y = 0;
    } else {
      this.y = this.startY + event.deltaY;
    }
    this.renderer.setStyle(
      bookingContainer,
      'transform',
      'translateY(' + this.y + 'px)');
  }

  onPanEnd(event: any): void {
    if (event.deltaY >= 100 && this.direction === 'down') {
      this.dismiss();
    } else {
      this.y = this.startY;
      this.renderer.setStyle(
        this.bookingContainer.nativeElement,
        'transform',
        'translateY(0px)');
    }
  }

  isBtnDisabled() {
    if (this.pod.is_free && this.userAcceptedTerms && this.userHasRequirement) {
      return false;
    }
    if (!this.pod.is_free && this.userAcceptedTerms && this.selectedPaymentMethod && this.userHasRequirement) {
      return false;
    }
    return true;
  }

  register() {
    this.router.navigate(['/register'])
    this.dismiss();
  }

  parseTime(time: string): ParsedTime {
    const splited = time.split(':')
    return {
      hours: parseInt(splited[0], 10),
      minutes: parseInt(splited[1], 10),
      secondes: parseInt(splited[2], 10),
    }
  }

  selectDate(selected: BookingSelection) {
    this.selected = selected;
  }

  setPaymentMethod(selected: PaymentMethod) {
    this.selectedPaymentMethod = selected;
  }

  setBillingAddress(selected: BillingAddress) {
    this.selectedBillingAddress = selected;
  }

  setCoupon(coupon: Coupon) {
    this.selectedCoupon = coupon;
  }

  calculateMargin () {
    const modalHeight = this.bookingContainer.nativeElement.clientHeight;
    const screenHeight = window.innerHeight;
    if (modalHeight) {
      this.marginTop = (screenHeight - modalHeight) + 'px';
    }
  }

  setSelection(selection: { from: Date, to: Date }) {
    this.selection = selection
  }

  hideAlert() {
    this.store.dispatch(clearBookingError())
  }
}
