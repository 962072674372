import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Booking } from 'src/app/types/booking';
import { Confirm } from 'src/app/types/global';
import { User } from 'src/app/types/auth';
import { ConfirmPage } from 'src/app/modals/confirm/confirm.page';
import { BookingService } from 'src/app/services/booking.service';
import { completeRide, extendBooking } from 'src/app/store/actions/ride.actions';
import { selectConnectedUser } from 'src/app/store/selectors/auth.selectors';
import { selectCurrentBooking, selectCurrentBookingIsExtendable } from 'src/app/store/selectors/ride.selectors';
import { filter, map, take } from 'rxjs/operators';
import { differenceInMinutes, eachMinuteOfInterval, parseISO } from 'date-fns';
import { range } from 'lodash'
import { StatusBar, Style } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';
@Component({
  selector: 'kabin-booking-ride',
  templateUrl: './ride.component.html',
  styleUrls: ['./ride.component.scss'],
})
export class RideComponent  implements OnInit {

  @Input() isInAutomaticBuffer: BehaviorSubject<boolean>
  @ViewChild('extensionCollapse') extensionCollapse;

  booking$: Observable<Booking>
  user$: Observable<User>
  bookingDuration: number
  bookingDuration$: Observable<number>
  bookingSlotMinutes = 5
  minutes: Array<number>;
  amount = 5;
  duration = 5
  isExtendable$: Observable<boolean>

  constructor(
    private store: Store,
    private modalController: ModalController,
    private bookingService: BookingService,
  ) {
    if (Capacitor.isNativePlatform()) {
      StatusBar.setStyle({style: Style.Light});
    }
    this.user$ = this.store.select(selectConnectedUser)
    this.booking$ = this.store.select(selectCurrentBooking)
    this.isExtendable$ = this.store.select(selectCurrentBookingIsExtendable)
    this.bookingDuration$ = this.booking$.pipe(
      map((booking: Booking) => booking.count * this.bookingSlotMinutes)
    )
  }

  ngOnInit() {

  }

  async confirmRideCompletion() {
    let confirm: Confirm = {
      title: 'Terminer la réservation',
      description: 'Êtes-vous sûr(e) de vouloir terminer cette réservation ?',
      icon: 'exit-outline'
    }
    const modal = await this.modalController.create({
      component: ConfirmPage,
      componentProps: {
        confirm
      },
    });
    modal.onDidDismiss().then(data => {
      if (data.data.action) {
        this.completeRide();
      }
    });
    modal.present();
  }

  completeRide() {
    this.store.dispatch(completeRide())
  }

  extend() {
    this.store.dispatch(extendBooking({ amount: this.amount }))
  }

  closeExension() {
    this.extensionCollapse.collapse();
  }
}
