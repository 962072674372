import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { HttpErrorResponse } from '@angular/common/http';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PopoverController } from '@ionic/angular';
import { PasswordPopoverComponent } from '../../popovers/password-popover/password-popover.component';
import { MeService } from 'src/app/services/me.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'kabin-account-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
})
export class AccountPasswordComponent implements OnInit {
  
  
  @Output() setAccountStep = new EventEmitter<Number>();
  public formPasswords: UntypedFormGroup;
  public alert: string;
  error: HttpErrorResponse;
  public passwordStrength: string;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public popoverCtrl: PopoverController,
    private meService: MeService,
    private authService: AuthService,
  ) {
    
  }
  
  updateAccountStep(step: number) {
    this.setAccountStep.emit(step);
  }

  ngOnInit() {
    this.formPasswords = this.formBuilder.group({
      current: ['', [
        Validators.required,
      ]],
      password: ['', [
        Validators.required,
        // Validators.pattern(/^(?=.{5,})(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$*%\-\._:\"!?\'(){}[\];^&+=]).*$/)
        Validators.pattern(/^(?=.{5,})(?=.*[A-Z]).*$/)
      ]],
      password_confirmation: ['', Validators.required]
    }, {
      validators: [
        this.passwordsMatchValidator
      ]
    })
  }

  async showPasswordPopover(ev: any) {
    const popover = await this.popoverCtrl.create({
      component: PasswordPopoverComponent,
      cssClass: `popover-fakemodal`,
      event: ev
    })
    return popover.present();
  }

  hideAlert(event: any, prop: string) {
    if (event) {
      this[prop] = null;
    }
  }

  changePassword() {
    this.authService.changePassword({ ...this.formPasswords.value }).subscribe(res => {
      this.updateAccountStep(1)
    }, err => {
      this.error = err;
    })
  }

  private passwordsMatchValidator = (form: UntypedFormGroup) => {
    if (form.get('password').value && form.get('password_confirmation').value) {
      return form.get('password').value === form.get('password_confirmation').value ? null : { mismatch: true };
    }
    return null;
  }
}
