import { HttpClient } from '@angular/common/http';
import {
  TRANSLOCO_LOADER,
  Translation,
  TranslocoLoader,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule,
  TRANSLOCO_INTERCEPTOR,
  TranslocoInterceptor,
} from '@ngneat/transloco';
import { Injectable, NgModule } from '@angular/core';
import { environment } from '../environments/environment';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { isDevMode } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(
    private http: HttpClient,
  ) {}

  getTranslation(lang: string) {
    // Skip faulty scoped language
    if (lang.length && lang[0] === '/') {
      lang = lang.slice(1);
    }
    return this.http.get<Translation>(environment.apiUrl+`locale/localization/${lang}?context=mobile&type=main`);
  }
}

export class CustomInterceptor implements TranslocoInterceptor {
  preSaveTranslation(translation: Translation, lang: string): Translation {
    return translation;
  }

  preSaveTranslationKey(key: string, value: string, lang: string): string {
    return value;
  }
}

@NgModule({
  exports: [ TranslocoModule ],
  imports: [ TranslocoLocaleModule.forRoot({
    langToLocaleMapping: {
      'en-GB': 'en-GB',
      'fr-FR': 'fr-FR'
    }
  }) ],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        defaultLang: 'fr-FR',
        fallbackLang: 'fr-FR',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
        availableLangs: ['fr-FR', 'en-GB'],
        missingHandler: {
          useFallbackTranslation: true,
        },
      })
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
    {
      provide: TRANSLOCO_INTERCEPTOR,
      useClass: CustomInterceptor
    }
  ]
})
export class TranslocoRootModule {}
