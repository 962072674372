import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Booking } from 'src/app/types/booking';
import {
  differenceInSeconds,
  intlFormatDistance,
} from 'date-fns';
import { Store } from '@ngrx/store';
import { openKabin } from 'src/app/store/actions/ride.actions';
import { Observable, interval, of, timer } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil, takeWhile } from 'rxjs/operators';
import { accessBooking } from 'src/app/store/actions/booking.actions';
import { selectBookingError } from 'src/app/store/selectors/booking.selectors';
import { clearBookingError } from 'src/app/store/actions/ride.actions';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'kabin-access-booking',
  templateUrl: './access-booking.component.html',
  styleUrls: ['./access-booking.component.scss'],
})
export class AccessBookingComponent  implements OnInit {

  @Input() booking: Booking;

  @ViewChild('rail', { static: false }) rail: ElementRef;
  @ViewChild('slider', { static: false }) slider: ElementRef;

  bgGradient: SafeStyle;
  textGradient: SafeStyle;
  sliderPosition = { x: 0, y: 0 };
  display$: Observable<string>
  timer$: Observable<number>
  canBeOpen$: Observable<boolean>
  entryBufferStartAt: Date
  error$: Observable<HttpErrorResponse>;

  constructor(
    private store: Store,
    private domSanitizer: DomSanitizer,
  ) {
    this.timer$ = timer(0, 1000)
    this.error$ = this.store.select(selectBookingError)
  }

  ngOnInit() {
    this.dynamicBackground(0)
    this.entryBufferStartAt = new Date(this.booking.entry_buffer_start_at)
    const secondsLeft$ = this.timer$.pipe(
      map(() => differenceInSeconds(this.entryBufferStartAt, new Date()))
    )

    this.canBeOpen$ = secondsLeft$.pipe(
      map((seconds: number) => seconds <= 0),
      filter((canBeOpen: boolean) => canBeOpen)
    )

    this.display$ = this.timer$.pipe(
      map(() => intlFormatDistance(new Date(this.booking.entry_buffer_start_at), new Date())),
      takeUntil(this.canBeOpen$)
    )
  }

  ngOnDestroy() : void {
    this.store.dispatch(clearBookingError())
  }

  dynamicBackground(percentage: number) {
    this.bgGradient = this.domSanitizer.bypassSecurityTrustStyle(`
      background: linear-gradient(90deg, rgba(255, 255, 255,1) 0%, rgba(255, 255, 255,1) ${percentage}%,
      rgba(56,105,55,0.29) ${percentage}%,
      rgba(56,105,55,0.29) 100%);`
    );
    this.textGradient = this.domSanitizer.bypassSecurityTrustStyle(`
      background: linear-gradient(90deg, rgba(56,105,55,0.29) 0%, rgba(56,105,55,0.29) ${percentage}%,
      rgba(255, 255, 255,1) ${percentage}%,
      rgba(255, 255, 255,1) 100%);`
    );
  }

  dragMove(event) {
    const op = this.rail.nativeElement.clientWidth - this.slider.nativeElement.clientWidth
    const percent = Math.round(((100 * (event.distance.x)) / op));
    this.dynamicBackground(percent);
  }

  dragEnd(event) {
    const x = this.rail.nativeElement.clientWidth - this.slider.nativeElement.clientWidth;
    if (event.distance.x > x) {
      this.getIn()
      this.sliderPosition = { x: 0, y: 0 };
      this.dynamicBackground(0);
    } else {
      this.resetSlider();
    }
  }

  resetSlider() {
    this.sliderPosition = { x: 0, y: 0 };
    this.dynamicBackground(0);
  }

  async getIn() {
    this.store.dispatch(accessBooking({ booking: this.booking }))
  }

  hideAlert() {
    this.store.dispatch(clearBookingError())
  }
}
