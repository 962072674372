import { createReducer, on } from "@ngrx/store"
import {
    setLocalizationFailure,
    getLocalizationFailure,
    getLocalizationSuccess,
    getLocalizationListSuccess,
    getLocalizationListFailure,
    setLocalizationUserSuccess,
    setLocalizationUserFailure,
    setLocalization,
    getCountryListSuccess,
    getCountryListFailure,
} from "../actions/localization.actions";
import { LocalizationResponse, Country } from "src/app/types/global";

export interface LocalizationState {
    localization: any;
    localizationList: Array<string>;
    countries: Country[];
    error: any;
}

export const initialState: LocalizationState = {
    localization: {},
    localizationList: [],
    error: null,
    countries: [],
}

const onFailure = (state: LocalizationState, { error }) => {
    return {
        ...state,
        localization: {},
        error,
    }
}
const onSuccess = (state: LocalizationState, { localization }): LocalizationState => {
    return {
        ...state,
        localization,
        error: null
    }
}

const onLocalizationSuccess = (state: LocalizationState, { lang }: { lang: string} ): LocalizationState => {
    return {
        ...state,
        localization: lang,
    }
}

const onGetLocalizationListSuccess = (state: LocalizationState, { localizationList }: { localizationList: Array<string> }): LocalizationState => {
    return {
        ...state,
        localizationList
    }
}

const onGetLocalizationListFailure = (state: LocalizationState, { error }) => {
    return {
        ...state,
        localizationList: [],
        error
    }
}

const onGetCountriesSuccess = (state: LocalizationState, { countries }: { countries: Array<Country> }): LocalizationState => {
    return {
        ...state,
        countries,
    }
}

export const reducer = createReducer(
    initialState,
    on(
        setLocalizationUserSuccess,
        onSuccess
    ),
    on(
        setLocalization,
        getLocalizationSuccess,
        onLocalizationSuccess
    ),
    on(
        getLocalizationListSuccess,
        onGetLocalizationListSuccess
    ),
    on(
        getLocalizationListFailure,
        onGetLocalizationListFailure
    ),
    on(
        getLocalizationFailure,
        setLocalizationFailure,
        setLocalizationUserFailure,
        getCountryListFailure,
        onFailure
    ),
    on(
        getCountryListSuccess,
        onGetCountriesSuccess,
    )
)