import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { concatMap, distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';
import { MeService } from 'src/app/services/me.service';
import { loadBillingAddresses, loadPaymentMethods, loadDiscounts } from 'src/app/store/actions/me.actions';
import { selectConnectionStatus } from 'src/app/store/selectors/auth.selectors';
import { selectIsGuest } from 'src/app/store/selectors/auth.selectors';
import { selectBillingAddresses, selectDefaultBillingAddress, selectDefaultPaymentMethod, selectPaymentMethods, selectDefaultDiscount } from 'src/app/store/selectors/me.selector';
import { PaymentMethod, BillingAddress, Coupon } from 'src/app/types/payment';
import { Pod } from 'src/app/types/pod';
import { AddPage as AddNewBillingAddress } from 'src/app/pages/billing/add/add.page'
import { AddPage as AddNewPayment } from 'src/app/pages/payment/add/add.page'
import { selectEnd, selectSelectedBillingAddress, selectSelectedPaymentMethod, selectStart, selectCoupon } from 'src/app/store/selectors/booking.selectors';
import { differenceInMinutes, endOfMonth, isAfter, isBefore, isSameDay, isThisMonth, isThisYear, subDays } from 'date-fns';
import { setSelectedBillingAddress, setSelectedPaymentMethod, setCoupon } from 'src/app/store/actions/booking.actions';
import { Card } from '@stripe/stripe-js';

@Component({
  selector: 'kabin-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent  implements OnInit {

  @Input() pod: Pod;

  @Output() onSelectPaymentMethod = new EventEmitter<{ paymentMethod: PaymentMethod }>();
  @Output() onSelectBillingAddress = new EventEmitter<{ billingAddress: BillingAddress }>();
  @Output() onSelectCoupon = new EventEmitter<any>();
  @Output() onDismiss = new EventEmitter<any>();

  currentPrice: number = 0;
  promoCode = '';
  paymentMethod = 'visa';
  isUserLoggedIn = false;
  paymentMethods: Array<PaymentMethod> = [];
  selectedPaymentMethod: PaymentMethod;
  billingAddresses: Array<BillingAddress> = [];
  selectedBillingAddress: BillingAddress;
  selectedCoupon: Coupon = null;
  promoCodeError: HttpErrorResponse;
  discounts: Array<Coupon> = [];


  showModalBillingAddress = true;
  showModalPayment = true;
  showModalPromo = true;
  defaultPaymentMethod$: Observable<PaymentMethod>;
  defaultBillingAddress$: Observable<BillingAddress>;
  defaultCoupon$:  Observable<Coupon>;
  selectedPaymentMethod$: Observable<PaymentMethod>;
  selectedBillingAddress$: Observable<BillingAddress>;
  selectedCoupon$: Observable<Coupon>;
  isConnected$: Observable<boolean>;
  isGuest$: Observable<boolean>;
  start$: Observable<Date>;
  end$: Observable<Date>;
  price$: Observable<number>
  modals = {
    cards: false,
    addresses: false,
    coupon: false,
  }

  constructor(
    private store: Store,
    private meService: MeService,
    private modalController: ModalController,
  ) {
    this.isConnected$ = this.store.select(selectConnectionStatus)
    this.isGuest$ = this.store.select(selectIsGuest)
    this.defaultPaymentMethod$ = this.store.select(selectDefaultPaymentMethod)
    this.defaultBillingAddress$ = this.store.select(selectDefaultBillingAddress)
    this.defaultCoupon$ = this.store.select(selectDefaultDiscount)
    this.selectedPaymentMethod$ = this.store.select(selectSelectedPaymentMethod)
    this.selectedBillingAddress$ = this.store.select(selectSelectedBillingAddress)
    this.selectedCoupon$ = this.store.select(selectCoupon)
    this.start$ = this.store.select(selectStart)
    this.end$ = this.store.select(selectEnd)
    this.store.dispatch(loadPaymentMethods())
    this.store.dispatch(loadBillingAddresses())
    this.store.dispatch(loadDiscounts())
  }

  ngOnInit() {
    this.store.select(selectDefaultPaymentMethod).pipe(
      filter((v: PaymentMethod) => v !== null),
      take(1)
    ).subscribe((paymentMethod: PaymentMethod) => {
      this.store.dispatch(setSelectedPaymentMethod({ selected: paymentMethod }))
    })

    this.store.select(selectDefaultBillingAddress).pipe(
      filter((v: BillingAddress) => v !== null),
      take(1)
    ).subscribe((billingAddress: BillingAddress) => {
      this.store.dispatch(setSelectedBillingAddress({ selected: billingAddress }))
    })

    this.store.select(selectDefaultDiscount).pipe(
      filter((v: Coupon) => v !== null),
      take(1)
    ).subscribe((coupon: Coupon) => {
      this.store.dispatch(setCoupon({ coupon }))
    })

    this.price$ = combineLatest([
      this.start$.pipe(filter(v => v !== null)),
      this.end$.pipe(filter(v => v !== null)),
      this.selectedCoupon$,
    ]).pipe(
      map(([start, end, coupon]: [Date, Date, Coupon]) => {
        const diff = differenceInMinutes(end, start)
        let price = diff * this.pod.prices.initial
        let discount = 0
        if (coupon) {
          if (coupon.type === 'minutes') {
            discount = coupon.discount * this.pod.prices.initial
            discount = discount > price ? price : discount
          }
          if (coupon.type === 'percent') {
            discount = price * (coupon.discount / 100)
          }
        }
        return price - discount
      }),
      distinctUntilChanged(),
    )
  }

  dismissAndReroute(route: string) {
    this.modalController.dismiss({
      dismissed: true,
      reroute: route
    });
  }

  openModal(key: string) {
    for(const modal in this.modals) {
      this.modals[modal] = key === modal ? true : false
    }
  }

  closeModal(key: string) {
    this.modals[key] = false
  }

  isAuthorized(card: Card) {
    return ['valid', 'soon'].includes(this.checkExpiry(card))
  }

  isValid(card: Card) {
    return this.checkExpiry(card) === 'valid'
  }

  checkExpiry(card: Card) {
    let lastDay = endOfMonth(new Date(card.exp_year, card.exp_month - 1))
    let currentEndOfMonth = endOfMonth(new Date())
    if (isAfter(lastDay, new Date())) {
      if (isThisYear(lastDay)) {
        if (isThisMonth(lastDay)) {
          if ( isBefore( subDays( currentEndOfMonth, 3), new Date()) || isSameDay(subDays( currentEndOfMonth, 3), new Date()) ) {
            return 'close'
          }
          if ( isBefore( subDays( currentEndOfMonth, 7), new Date()) || isSameDay(subDays( currentEndOfMonth, 7), new Date()) ) {
            return 'soon'
          }
        }
      }
      return 'valid'
    }
    return 'invalid'
  }

  async addBillingAddress() {
    const modal = await this.modalController.create({
      component: AddNewBillingAddress,
      cssClass: 'modal-booked',
      componentProps: {
        fromModal: true
      }
    });
    await modal.present();
  }

  async addPaymentMethod() {
    const modal = await this.modalController.create({
      component: AddNewPayment,
      cssClass: 'modal-booked',
      componentProps: {
        fromModal: true
      }
    });
    await modal.present();
  }
}
