import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslocoService } from '@ngneat/transloco';
import { languageSelectionSuccess } from 'src/app/store/actions/auth.actions';
import { selectLocalization, selectLocale } from 'src/app/store/selectors/localization.selectors';
import { take } from 'rxjs/operators';

@Component({
  selector: 'kabin-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss'],
})
export class LanguageComponent  implements OnInit {
  languageForm: UntypedFormGroup = this.formBuilder.group({});

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store,
    public translocoService: TranslocoService,
  ) {
  }

  ngOnInit() {

  }

  validateLanguage () {
    this.store.dispatch(languageSelectionSuccess({ language: this.translocoService.getActiveLang()}));
  }
}
