import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { debounce, debounceTime, finalize } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';
import { findIndex } from 'lodash'

import { default as countries } from 'src/app/utils/countries.json'

import { KeyValue } from '@angular/common';
import { Store } from '@ngrx/store';
import { MeService } from 'src/app/services/me.service';
import { loadBillingAddresses } from 'src/app/store/actions/me.actions';
import { GoogleService } from 'src/app/services/google.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'kabin-add',
  templateUrl: './add.page.html',
  styleUrls: ['./add.page.scss'],
})

export class AddPage implements OnInit {

  @Input() fromModal: boolean = false;

  @ViewChild('emptydiv', { static: true }) emptydiv: ElementRef

  loading = false;
  formSearch: UntypedFormGroup = this.formBuilder.group({
    search: ['', []],
  });
  formBilling: UntypedFormGroup = this.formBuilder.group({
    name: ['', [Validators.maxLength(20)]],
    type: ['perso', [Validators.required]],
  });
  formAddress: UntypedFormGroup = this.formBuilder.group({
    line1: ['', [Validators.required, Validators.minLength(5)]],
    line2: [''],
    line3: [''],
    postal_code: ['', [Validators.required, Validators.minLength(2)]],
    city: ['', [Validators.required, Validators.minLength(2)]],
    state: [''],
    company_name: [''],
    country: [''],
  });
  error: string;
  selection: any = null;
  action: string = 'back';
  countries: {[key: string]: string} = countries.fr;
  placesPredictions: google.maps.places.AutocompletePrediction[]
  selectedPlace: boolean = false

  constructor(
    private formBuilder: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private navController: NavController,
    private router: Router,
    private modalCtrl: ModalController,
    private store: Store,
    private translocoService: TranslocoService,
    private meService: MeService,
    private googleService: GoogleService,
    private notificationService: NotificationService,
  ) {
    if (Capacitor.isNativePlatform()) {
      StatusBar.setStyle({style: Style.Dark});
    }
    if (translocoService.getActiveLang()) {
      this.countries = countries[translocoService.getActiveLang()]
    }
  }

  async ngOnInit() {
    if (this.fromModal) {
      this.action = 'dismiss'
    }
    const google = await this.googleService.load()
    const service = new google.maps.places.AutocompleteService()
    this.formSearch.get('search').valueChanges.pipe(
      debounceTime(600),
    ).subscribe((input: string) => {
      if (input === '') {
        this.placesPredictions = []
      } else {
        service.getPlacePredictions({ input }, (results: google.maps.places.AutocompletePrediction[]) => {
          this.placesPredictions = results
        })
      }
    })
  }

  selectPlace(place: google.maps.places.AutocompletePrediction) {
    this.onClear(null)
    const service = new google.maps.places.PlacesService(this.emptydiv.nativeElement)
    service.getDetails({ placeId: place.place_id }, (placeResult: google.maps.places.PlaceResult) => {
        try {
          const country = placeResult.address_components.find((component: google.maps.GeocoderAddressComponent) => component.types.includes('country')) || { long_name: '', short_name: '' }
          const city = placeResult.address_components.find((component: google.maps.GeocoderAddressComponent) => component.types.includes('locality')) || { long_name: '', short_name: '' }
          const postalCode = placeResult.address_components.find((component: google.maps.GeocoderAddressComponent) => component.types.includes('postal_code')) || { long_name: '', short_name: '' }
          const line1StreetNumber = placeResult.address_components.find((component: google.maps.GeocoderAddressComponent) => component.types.includes('street_number')) || { long_name: '', short_name: '' }
          const line1Route = placeResult.address_components.find((component: google.maps.GeocoderAddressComponent) => component.types.includes('route')) || { long_name: '', short_name: '' }
          const state = placeResult.address_components.find((component: google.maps.GeocoderAddressComponent) => {
            if (component.types.includes('administrative_area_level_2'))
              return component.types.includes('administrative_area_level_2')
            else return component.types.includes('administrative_area_level_1')
          }) || {long_name: '', short_name: ''}
          const companyName = placeResult.name
          this.formSearch.controls['search'].setValue('', {emitEvent: false})
          this.formAddress.patchValue({
            country: country?.short_name,
            line1: `${line1StreetNumber?.long_name} ${line1Route?.long_name}`,
            city: city.long_name,
            postal_code: postalCode.long_name,
            state: state.long_name,
            company_name: this.formBilling.controls['type'].value === 'pro' ? companyName : ''
          })
          this.selectedPlace = true
          this.placesPredictions = []
        } catch (error) {
          this.notificationService.notify({
            type: "danger",
            title: 'Addresse invalide',
            body: 'L\'adresse que vous avez sélectionnée est incomplète.',
          });
        }
      }
    )
  }

  onClear(e: Event) {
    this.formAddress.reset({
      line1: '',
      line2: '',
      line3: '',
      postal_code: '',
      city: '',
      state: '',
      company_name: '',
      country: ''
    })
    this.selectedPlace = false
  }

  addBillingAddress() {
    this.loading = true;
    this.meService.addBillingAddress({...this.formBilling.value, ...this.formAddress.value}).pipe(
      finalize(() => this.loading = false)
    ).subscribe(() => {
      this.goBack();
      this.store.dispatch(loadBillingAddresses())
    }, (err: HttpErrorResponse) => {
      this.error = err.error;
    });
  }

  goBack() {
    if (this.fromModal) {
      this.modalCtrl.dismiss({ dismissed: true });
    } else {
      this.navController.back();
    }
  }

  checkCountry() {
    this.formBilling.controls['country_name'].setValue(this.countries[this.formBilling.controls['country'].value])
  }
}
