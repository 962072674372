import { Injectable } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GoogleService {

  public loader: Loader

  constructor() {
    this.loader = new Loader({
      apiKey: environment.apiKey,
      version: 'weekly',
      libraries: ['places']
    });
  }

  async load() {
    return await this.loader.load()
  }
}
