import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import type { Booking } from 'src/app/types/booking';
import { isEmpty } from 'lodash'
import { Store } from '@ngrx/store';
import { selectBookingStatus, selectCurrentBooking, selectUserDigicode, selectDigicodeSuffix } from 'src/app/store/selectors/ride.selectors';
import { completeCheckin } from 'src/app/store/actions/ride.actions';
import { Picture } from 'src/app/types/global';
import { mergeMap, take, filter, map } from 'rxjs/operators';
import { CheckinReport, CheckinReportPayload, CheckinStep } from 'src/app/types/ride';
import { RideService } from 'src/app/services/ride.service';
import { ModalController } from '@ionic/angular';
import { ConfirmPage } from 'src/app/modals/confirm/confirm.page';


// TODO: handle reports, forcing continue for now
@Component({
  selector: 'kabin-checkin',
  templateUrl: './checkin.component.html',
  styleUrls: ['./checkin.component.scss'],
})
export class CheckinComponent implements OnInit {

  booking$: Observable<Booking>
  status$: Observable<string>
  hasIssues: boolean = false
  checkinDisabled$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  reportIsSent: boolean = false
  digicodeUser$: Observable<string>
  digicodeSuffix$: Observable<string>
  qrCodeData$: Observable<string>
  issues: Array<CheckinReport> = [
    {
      clean: true,
      icon: 'table',
      name: 'Bureau',
      reason: null,
      comment: null,
      type: 'desk',
      choices: [
        {
          trad: 'liquid',
          value: 'spilled liquid',
          label: 'Liquide renversé'
        },
        {
          trad: 'rubbish',
          value: 'rubbish',
          label: 'Présence de détritus'
        },
        {
          trad: 'stuff',
          value: 'personnals stuff',
          label: 'Présence d’objets personnel'
        },
        {
          trad: 'furniture',
          value: 'damaged furniture',
          label: 'Mobilier endommagé'
        },
        {
          trad: 'electrical',
          value: 'broken electrical outlet',
          label: 'Prise cassée'
        },
        {
          trad: 'other',
          value: 'other',
          label: 'Autre'
        }
      ]
    },
    {
      clean: true,
      icon: 'chair',
      name: 'Sièges',
      reason: null,
      comment: null,
      type: 'seats',
      choices: [
        {
          trad: 'liquid',
          value: 'spilled liquid',
          label: 'Liquide renversé'
        },
        {
          trad: 'rubbish',
          value: 'rubbish',
          label: 'Présence de détritus'
        },
        {
          trad: 'stuff',
          value: 'personnals stuff',
          label: 'Présence d’objets personnel'
        },
        {
          trad: 'furniture',
          value: 'damaged furniture',
          label: 'Mobilier endommagé'
        },
        {
          trad: 'other',
          value: 'other',
          label: 'Autre'
        }
      ]
    },
    {
      clean: true,
      icon: 'floor',
      name: 'Sol',
      reason: null,
      comment: null,
      type: 'ground',
      choices: [
        {
          trad: 'other',
          value: 'not clean',
          label: 'Problèmes de propreté'
        },
        {
          trad: 'rubbish',
          value: 'rubbish',
          label: 'Présence de détritus'
        },
        {
          trad: 'stuff',
          value: 'personnals stuff',
          label: 'Présence d’objet personnel'
        },
        {
          trad: 'other',
          value: 'other',
          label: 'Autre'
        }
      ]
    },
  ]

  public pictures: Array<Picture> = [];

  public checkinSteps: Array<CheckinStep> = [
    {
      step: 'Etape 1',
      type: 'seats',
      title: 'Etat des sièges',
      description: "Merci de vérifier l'état des banquettes (présence de tâches, détritus…)",
      icon: 'chair',
      labelYes: 'Propre',
      labelNo: 'Pas propre',
      is_clean: null,
      reason: null,
      comment: null,
    },
    {
      step: 'Etape 2',
      type: 'table',
      title: 'Etat de la table',
      description: "Merci de vérifier l'état de la table (présence de tâche, détritus...)",
      icon: 'table',
      labelYes: 'Propre',
      labelNo: 'Pas propre',
      is_clean: null,
      reason: null,
      comment: null,
    },
    {
      step: 'Etape 3',
      type: 'other',
      title: 'Autres',
      description: "Avez-vous une remarque concernant l'état de la Kabin en général (détérioration, présence d'objets ou de détritus....) ?",
      icon: 'trash',
      labelYes: 'Propre',
      labelNo: 'Pas propre',
      is_clean: null,
      reason: null,
      comment: null,
    }
  ];


  constructor(
    private store: Store,
    private rideService: RideService,
    private modalCtrl: ModalController
  ) {
    this.booking$ = this.store.select(selectCurrentBooking)
    this.status$ = this.store.select(selectBookingStatus)
    this.digicodeUser$ = this.store.select(selectUserDigicode);
    this.digicodeSuffix$ = this.store.select(selectDigicodeSuffix);
    this.qrCodeData$ = this.booking$.pipe(
      filter((booking: Booking) => booking.metadata?.flexaccess_event),
      map((booking: Booking) => booking.metadata.flexaccess_event.attendees[0]?.token.data),
    )
  }

  ngOnInit() {
    //
  }

  checkinFirstStep() {
    if (this.reports.length) {
      this.hasIssues = true;
      this.checkinDisabled$.next(true)
      return
    }

    this.completeCheckin(true)
  }

  checkIfCanProcessCheckin() {
    if (!this.pictures.length) {
      this.checkinDisabled$.next(true)
      return
    }
    const reportsUnchecked = this.issues
      .filter((report: CheckinReport) => !report.clean)
      .filter((report: CheckinReport) => {
        return (report.reason !== 'other' && report.reason !== null) || (report.reason == 'other' && report.comment !== null) ? false : true
      })

    if (isEmpty(reportsUnchecked)) {
      this.checkinDisabled$.next(false)
    } else {
      this.checkinDisabled$.next(true)
    }
  }

  get reports() {
    return this.issues.filter((checkinReport: CheckinReport) => !checkinReport.clean)
  }

  removePicture(picture: Picture) {
    this.booking$.pipe(
      take(1),
      mergeMap((booking: Booking) => {
        return this.rideService.deleteCheckinPicture(booking, picture)
      })
    ).subscribe(() => {
      this.pictures = this.pictures.filter((pic: Picture) => pic.name !== picture.name)
    }, err => {
      console.log('err', err)
    })
  }

  addNewPicture(picture: Picture, step: string) {
    this.pictures.push(picture)
    this.checkIfCanProcessCheckin()
  }

  async sendReport() {
    const reports: CheckinReportPayload[] = this.reports.map((report: CheckinReport) => {
      const { clean, comment, reason, type } = report
      return { clean, comment, reason, type }
    })
    this.booking$.pipe(take(1)).subscribe(booking => {
      this.rideService.sendCheckinReport(booking, reports)
        .subscribe((response) => {
          this.reportIsSent = true
          // this.completeCheckin(true);
        }, err => {
          // TODO: handle errors
        });
    });

    const confirmModal = await this.modalCtrl.create({
      component: ConfirmPage,
      componentProps: {
        confirm: {
          title: 'ride.validation.title',
          description: 'ride.validation.subtitle',
          icon: 'alert-outline',
        }
      }
    })

    confirmModal.onDidDismiss().then(data => {
      if (data?.data) {
        this.completeCheckin(data.data.action)
      }
    })
    confirmModal.present();
  }

  completeCheckin(selection: boolean) {
    this.store.dispatch(completeCheckin({ continue: selection }))
  }
}
