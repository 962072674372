import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { finalize, take } from 'rxjs/operators';
import { CountryCode, parsePhoneNumberWithError, NationalNumber } from 'libphonenumber-js/max';
import { Store } from '@ngrx/store';
import { registerSelectCountry, sendCode } from 'src/app/store/actions/auth.actions';
import { Observable } from 'rxjs';
import { selectAuthError, selectAuthRegisterCountry } from 'src/app/store/selectors/auth.selectors';
import type { Country } from 'src/app/types/global';
import { default as countries } from 'src/app/utils/countries-phone.json'
import { selectLocalization, selectAvailableCountries, selectDefaultCountries } from 'src/app/store/selectors/localization.selectors';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'kabin-register-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss'],
})
export class PhoneComponent  implements OnInit {

  phoneForm: UntypedFormGroup = this.formBuilder.group({
    phone: ['', [Validators.required, Validators.pattern(/^[0-9\s]{6,20}$/)]],
    sms: [false, [Validators.requiredTrue]]
  });
  countries: Country[];
  errorMessage = '';
  country$: Observable<Country>;
  error$: Observable<any>;
  language: string = 'fr';
  apiUrl: string = environment.apiUrl.replace('/api/', '/')

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store,
    private translocoService: TranslocoService,
  ) {
    this.error$ = this.store.select(selectAuthError);
    this.country$ = store.select(selectDefaultCountries)
    this.country$.subscribe(country => {
      store.dispatch(registerSelectCountry({country}));
    })
    store.select(selectLocalization).subscribe(lang => {
      this.language = lang
    })
    store.select(selectAvailableCountries).subscribe(countries => {
      this.countries = countries;
    })
    console.log({apiUrl: this.apiUrl})
  }

  ngOnInit() {
  }

  selectCountry({ target }) {
    this.store.dispatch(registerSelectCountry({ country: this.countries.find((country: Country) => country.iso2 === target.value) }))
  }

  validatePhoneCode() {
    const phone = this.validatePhoneNumber();
    if (phone) {
      this.store.dispatch(sendCode({ phone }));
    }
  }

  validatePhoneNumber() {
    try {
      let selectedCountry;
      this.country$.pipe(take(1)).subscribe((country: Country) => {
        selectedCountry = country;
      })
      const phoneNumber = parsePhoneNumberWithError(this.phoneForm.controls.phone.value, selectedCountry.iso2);
      if (phoneNumber.getType() !== 'MOBILE') {
        this.errorMessage = this.translocoService.translate('register.phone.error.type');
        return false;
      }
      if (!phoneNumber.isValid()) {
        this.errorMessage = this.translocoService.translate('register.phone.error.invalid');
        return false;
      }
      // If user is in list of required SMS
      if (!this.phoneForm.controls.sms.value) {
        this.errorMessage = this.translocoService.translate('register.phone.sms.error')
        return false;
      }
      return phoneNumber.nationalNumber;
    } catch ({ message }) {
      switch(message) {
        case 'TOO_SHORT':
          this.errorMessage = this.translocoService.translate('register.phone.error.short');
          return false;
        case 'TOO_LONG':
          this.errorMessage = this.translocoService.translate('register.phone.error.long');
          return false;
      }
      console.log('error', message);
    }
  }

  hideAlert(event: any, prop: string) {
    if (event) this[prop] = null
  }

}
