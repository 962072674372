import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Confirm } from 'src/app/types/global'
import { StatusBar, Style } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'kabin-confirm',
  templateUrl: './confirm.page.html',
  styleUrls: ['./confirm.page.scss'],
})
export class ConfirmPage implements OnInit {

  @Input() confirm: Confirm;

  constructor(
    private modalCtrl: ModalController
  ) {
    if (Capacitor.isNativePlatform()) {
      StatusBar.setStyle({style: Style.Dark});
    }
  }

  ngOnInit() {
  }

  answer(action: boolean) {
    this.modalCtrl.dismiss({ dismissed: true, action });
  }

}
