import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kabin-email-popover',
  templateUrl: './email-popover.component.html',
  styleUrls: ['./email-popover.component.scss'],
})
export class EmailPopoverComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
