import { 
    SET_LOCALIZATION, 
    setLocalization,
    setLocalizationFailure,
    GET_LOCALIZATION,
    getLocalizationSuccess,
    getLocalizationFailure,
    GET_LOCALIZATION_LIST,
    getLocalizationListFailure,
    getLocalizationListSuccess,
    GET_LOCALIZATION_USER,
    SET_LOCALIZATION_USER,
    setLocalizationUserSuccess,
    setLocalizationUserFailure,
    GET_COUNTRY_LIST,
    getCountryListSuccess,
    getCountryListFailure,
} from "../actions/localization.actions";
import { LocalizationService } from "src/app/services/localization.service";
import { selectLocalization } from "../selectors/localization.selectors";
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect,  } from '@ngrx/effects';
import { of, from, Observable } from 'rxjs';
import { catchError, concatMap, exhaustMap, filter, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from "..";
import { Action } from "rxjs/internal/scheduler/Action";
import { selectConnectionStatus } from "../selectors/auth.selectors";
import { LocalizationResponse } from "src/app/types/global";
import type { Country } from 'src/app/types/global'

@Injectable()
export class LocalizationEffects {

  setLocalizationUser$ = createEffect(() => 
    this.actions$.pipe(
      ofType(SET_LOCALIZATION_USER),
      exhaustMap(({ lang }: { lang: string }) => 
        this.localizationService.setLocalizationUser(lang).pipe(
          map((localization: LocalizationResponse) => setLocalizationUserSuccess({ localization })),
          catchError(error => of(setLocalizationUserFailure(error)))
        )
      )
    )
  );

  getLocalizationList$ = createEffect(() => 
    this.actions$.pipe(
      ofType(GET_LOCALIZATION_LIST),
      withLatestFrom(this.store$),
      exhaustMap(() => {
        return this.localizationService.getLocalizationList().pipe(
          map((localizationList: Array<string>) => getLocalizationListSuccess({ localizationList })),
          catchError(error => of(getLocalizationListFailure(error)))
        )
      })
    )
  );

  getCountryList$ = createEffect(() => 
    this.actions$.pipe(
      ofType(GET_COUNTRY_LIST),
      withLatestFrom(this.store$),
      exhaustMap(() => {
        return this.localizationService.getCountries().pipe(
          map((countries: Array<Country>) => getCountryListSuccess({ countries })),
          catchError(error => of(getCountryListFailure(error)))
        )
      })
    )
  );

  constructor(
    private actions$: Actions,
    private localizationService: LocalizationService,
    private store$: Store<AppState>,
  ) { }
}