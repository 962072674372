import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
// import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { NotificationService } from './notification.service';
import { RidePage } from '../modals/ride/ride.page';
import { Capacitor } from '@capacitor/core';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { FCM } from '@capacitor-community/fcm';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class FcmService {

  fcmToken: string;

  constructor(
    // private firebaseX: FirebaseX,
    private notificationService: NotificationService,
    private modalController: ModalController,
    private router: Router
  ) {
    if (Capacitor.isNativePlatform()) {
      PushNotifications.addListener('registration', async ({ value }) => {
        let token = value; // Push token for Android

        // Get FCM token instead the APN one returned by Capacitor
        if (Capacitor.getPlatform() === 'ios') {
          const { token: fcm_token } = await FCM.getToken();
          token = fcm_token;
        }
        this.fcmToken = token;
      });
      PushNotifications.addListener('registrationError', err => {
        console.error('Registration error: ', err.error);
      });
      PushNotifications.addListener('pushNotificationReceived', async (notification: PushNotificationSchema) => {
        console.log('pushNotificationReceived', notification)
        const { title, body } = notification;
        this.notificationService.notify({
          title,
          body,
        });
      })
      PushNotifications.addListener('pushNotificationActionPerformed', async ({ actionId, notification }: ActionPerformed) => {
        console.log('pushNotificationActionPerformed', notification);
        const { data } = notification;
        if (data.booking && data.type === 'BookingWillStart') {
          this.router.navigate(['/booked/' + JSON.parse(data.booking).uuid])
        }
        if (data.booking && data.type === 'BookingWillEnd') {
          const modal = await this.modalController.create({
            component: RidePage,
            cssClass: 'modal-ride',
            componentProps: {
              booking: JSON.parse(data.booking)
            }
          });
          await modal.present();
        }
      })
    }
  }

  async getToken() {
    try {
      if (Capacitor.isNativePlatform()) {
        await FCM.getToken();
      }
    } catch (error) {
      throw error;
    }
  }

  register(): void {
    PushNotifications.checkPermissions().then(check  =>{
      PushNotifications.requestPermissions().then(permission => {
        if (permission.receive === 'granted') {
          PushNotifications.register();
        } else {
          console.log('NO PERMS FOR PUSH NOTIFICATIONS');
        }
      });
    });
  }
}
