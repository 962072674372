import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Booking } from 'src/app/types/booking';
import { BookingService } from 'src/app/services/booking.service';
import { completeCheckout } from 'src/app/store/actions/ride.actions';
import { selectBookingStatus, selectCurrentBooking, selectPodStatus } from 'src/app/store/selectors/ride.selectors';
import { Picture } from 'src/app/types/global';
import { CheckoutCheck } from 'src/app/types/ride';
import { PodStatus } from 'src/app/types/pod';
import { RideService } from 'src/app/services/ride.service';
import { mergeMap, take } from 'rxjs/operators';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'kabin-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent  implements OnInit {


  booking$: Observable<Booking>
  podStatus$: Observable<PodStatus>
  status$: Observable<string>
  comment = '';
  checks: CheckoutCheck[] = [
    {
      label: 'Bureau',
      is_clean: false,
      icon: 'desk',
    },
    {
      label: 'Sièges',
      is_clean: false,
      icon: 'chair',
    },
    {
      label: 'Branchements',
      is_clean: false,
      icon: 'electric-plug',
    },
    {
      label: 'Sol',
      is_clean: false,
      icon: 'floor',
    }
  ];
  step: number = 1 // STEP 2 = LOADING CLOSING DOOR
  pictures: Array<Picture> = [];

  constructor(
    private bookingService: BookingService,
    private store: Store,
    private rideService: RideService,
  ) {
    if (Capacitor.isNativePlatform()) {
      StatusBar.setStyle({style: Style.Light});
    }
    this.booking$ = this.store.select(selectCurrentBooking)
    this.podStatus$ = this.store.select(selectPodStatus)
    this.status$ = this.store.select(selectBookingStatus)
  }

  ngOnInit() {}

  removePicture(picture: Picture) {
    this.booking$.pipe(
      take(1),
      mergeMap((booking: Booking) => {
        return this.rideService.deleteCheckoutPicture(booking, picture)
      })
    ).subscribe(() => {
      this.pictures = this.pictures.filter((pic: Picture) => pic.name !== picture.name)
    }, err => {
      console.log('err', err)
    })
  }

  addNewPicture(picture: Picture) {
    this.pictures.push(picture)
  }

  checkoutIsChecked(): boolean {
    return this.checks.every((check: CheckoutCheck) => check.is_clean);
  }

  exitKabin() {
    // TODO: end ride and go to exit landing
  }

  validateCheck() {
    this.store.dispatch(completeCheckout({ reports: {
      desktop: this.checks[0].is_clean,
      seats: this.checks[1].is_clean,
      connections: this.checks[2].is_clean,
      ground: this.checks[3].is_clean,
      comment: this.comment
    }}))
    this.step = 2;
  }

}
