import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BillingAddress, PaymentMethod, Coupon } from '../types/payment';
import { Booking } from '../types/booking';
import { User } from '../types/auth';
import { format } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class MeService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  getProfile(): Observable<User> {
    return this.httpClient.get<User>(`${environment.apiUrl}me/profile`);
  }

  getPaymentMethods(): Observable<PaymentMethod[]> {
    return this.httpClient.get<PaymentMethod[]>(`${environment.apiUrl}me/payment`)
  }

  addPaymentMethod(data: any) {
    return this.httpClient.post(`${environment.apiUrl}me/payment`, data)
  }

  deletePaymentMethod(method: PaymentMethod) {
    return this.httpClient.delete(`${environment.apiUrl}me/payment/${method.id}`)
  }

  setDefaultPaymentMethod(data: { pm_id: string }) {
    return this.httpClient.put(`${environment.apiUrl}me/payment/default`, data)
  }

  getBillingAddresses(): Observable<BillingAddress[]> {
    return this.httpClient.get<BillingAddress[]>(`${environment.apiUrl}me/billing`)
  }

  addBillingAddress(data: any) {
    return this.httpClient.post(`${environment.apiUrl}me/billing`, data)
  }

  deleteBillingAddress(adress: BillingAddress) {
    return this.httpClient.delete(`${environment.apiUrl}me/billing/${adress.uuid}`)
  }

  setDefaultBillingAddress(selected: BillingAddress) {
    return this.httpClient.put(`${environment.apiUrl}me/billing/${selected.uuid}/default`, {})
  }

  updateProfile(data: any) {
    return this.httpClient.put(`${environment.apiUrl}me/profile`, data)
  }

  getNextBookings(queries: { from?: Date, to?: Date } = null): Observable<Booking[]> {
    const url = `${environment.apiUrl}me/booking/next`
    let q = queries ? `?` : ''
    if (queries) {
      for (const key in queries) {
        q += `${key}=${format(queries[key], 'yyyy-MM-dd')}&`
      }
    }
    return this.httpClient.get<Booking[]>(url + q);
    // ?from=${format(from, 'yyyy-MM-dd')}&to=${format(to, 'yyyy-MM-dd')}
  }

  getPastBookings(queries: { from?: Date, to?: Date } = null): Observable<Booking[]> {
    const url = `${environment.apiUrl}me/booking/past`
    let q = queries ? `?` : ''
    if (queries) {
      for (const key in queries) {
        q += `${key}=${format(queries[key], 'yyyy-MM-dd')}&`
      }
    }
    return this.httpClient.get<Booking[]>(url + q);
  }

  getDiscounts(): Observable<Coupon[]> {
    return this.httpClient.get<Coupon[]>(`${environment.apiUrl}me/discounts`)
  }
}
