import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectPaymentMethods } from 'src/app/store/selectors/me.selector';
import { PaymentMethod } from 'src/app/types/payment';
import { setSelectedPaymentMethod } from 'src/app/store/actions/booking.actions';
import { selectSelectedPaymentMethod } from 'src/app/store/selectors/booking.selectors';
import { endOfMonth, isAfter, isBefore, isSameDay, isThisMonth, isThisYear, subDays } from 'date-fns';
import { Card } from '@stripe/stripe-js';


@Component({
  selector: 'kabin-booking-payment-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss'],
})
export class CardsComponent  implements OnInit {

  @Output() onClose = new EventEmitter<void>();
  @Output() openPaymentPage = new EventEmitter<void>();

  paymentMethods$: Observable<PaymentMethod[]>
  selectedPaymentMethod$: Observable<PaymentMethod>

  constructor(
    private store: Store,
  ) {
    this.paymentMethods$ = this.store.select(selectPaymentMethods)
    this.selectedPaymentMethod$ = this.store.select(selectSelectedPaymentMethod)
  }

  ngOnInit() {}

  selectPaymentMethod(method: PaymentMethod) {
    this.store.dispatch(setSelectedPaymentMethod({ selected: method }))
  }

  isAuthorized(card: Card) {
    return ['valid', 'soon'].includes(this.checkExpiry(card))
  }

  isValid(card: Card) {
    return this.checkExpiry(card) === 'valid'
  }

  getValidColor(card: Card) {
    switch (this.checkExpiry(card)) {
      case 'soon':
        return 'yellow'
      case 'invalid': 
        return 'grey';
      case 'close': 
        return 'red';
      default: 
        return 'success'
    }
  }

  checkExpiry(card: Card) {
    let lastDay = endOfMonth(new Date(card.exp_year, card.exp_month - 1))
    let currentEndOfMonth = endOfMonth(new Date())
    if (isAfter(lastDay, new Date())) {
      if (isThisYear(lastDay)) {
        if (isThisMonth(lastDay)) {
          if ( isBefore( subDays( currentEndOfMonth, 3), new Date()) || isSameDay(subDays( currentEndOfMonth, 3), new Date()) ) {
            return 'close'
          }
          if ( isBefore( subDays( currentEndOfMonth, 7), new Date()) || isSameDay(subDays( currentEndOfMonth, 7), new Date()) ) {
            return 'soon'
          }
        }
      }
      return 'valid'
    }
    return 'invalid'
  }
}
