// Angular
import { Directive, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

// Ionic
import { IonInput } from '@ionic/angular';

// Rxjs
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

// Text-mask
import { createTextMaskInputElement } from 'text-mask-core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js/max';

export const validatePhoneNumber = (country: CountryCode): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    try {
      const phoneNumber = parsePhoneNumber(control.value, country);
      return phoneNumber.isValid()  ?  null : {
        unvalidPhoneNumber: {
          unvalid: control.value
        }
      };
    } catch (error) {
      return { error };
    }
  };
};
/**
 * ion-mask directive, based on text-mask module
 */
@Directive({
  selector: '[kabinPhoneMask]',
  providers: [IonInput],
})
export class IonMaskDirective implements OnInit, OnChanges ,OnDestroy {

  @Input('kabinPhoneMask')
  private mask: Array<any> = [];
  private onDestroy: Subject<void> = new Subject<void>();

  constructor(public ionInput: IonInput) { }

  public ngOnInit() {
    this.configureInput();
  }

  public ngOnChanges() {
    this.updateInput();
  }

  public ngOnDestroy() {
    this.onDestroy.next();
  }

  public async updateInput() {
    const input = await this.ionInput.getInputElement();
    const maskedInput = createTextMaskInputElement({
      inputElement: input,
      mask: this.mask,
      guide: false
    })

    this.ionInput
        .ionChange
        .pipe(takeUntil(this.onDestroy))
        .subscribe((event: CustomEvent) => {
          const { value } = event.detail;
          maskedInput.update(value);
          this.ionInput.value = input.value;
        });
  }

  public async configureInput() {
    const input = await this.ionInput.getInputElement();
    const maskedInput = createTextMaskInputElement({
      inputElement: input,
      mask: this.mask,
      guide: false
    });
    if (!input.value) {
      this.ionInput
        .ionChange
        .pipe(takeUntil(this.onDestroy))
        .subscribe((event: CustomEvent) => {
          const { value } = event.detail;
          maskedInput.update(value);
          this.ionInput.value = input.value;
        });
    } else {
      let value = input.value;
      maskedInput.update(value);
      this.ionInput.value = input.value;
    }
  }

}
