import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { extendBooking } from 'src/app/store/actions/ride.actions';
import { Booking } from 'src/app/types/booking';
import { differenceInMinutes, eachMinuteOfInterval, parseISO } from 'date-fns';
import { range as _range } from 'lodash' 
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { ModalController } from '@ionic/angular';
import { ConfirmPage } from 'src/app/modals/confirm/confirm.page';
import { Confirm } from 'src/app/types/global';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, skipUntil, take, takeWhile, withLatestFrom, switchMap, } from 'rxjs/operators';
import { selectCurrentBooking } from 'src/app/store/selectors/ride.selectors';

@Component({
  selector: 'kabin-ride-ride-extension',
  templateUrl: './extension.component.html',
  styleUrls: ['./extension.component.scss'],
})
export class ExtensionComponent  implements OnInit {

  @Input() booking: Booking;
  @Output() extensionDone = new EventEmitter<Number>();

  amount = 5;
  minutes$: Observable<any>;
  booking$: Observable<Booking>;
  hasMinutes$: Observable<boolean>;

  constructor(
    private store: Store,
    private modalController: ModalController,
    private translocoService: TranslocoService,
  ) { }

  ngOnInit() {
    this.booking$ = this.store.select(selectCurrentBooking)
    this.minutes$ = this.booking$
      .pipe(
        distinctUntilChanged(),
        switchMap((booking: Booking) => {
          return of(this.calculateMinutes(booking));
        })
      );
    this.hasMinutes$ = this.minutes$
      .pipe(
        distinctUntilChanged(),
        switchMap((minutes: number[]) => {
          return of(!!minutes.length);
        })
      );
  }

  calculateMinutes (booking: Booking) {
    const minutesUntilNextBooking = differenceInMinutes(parseISO(booking.expandableTo), parseISO(booking.dynamic_buffer_end_at))
    return _range(5, minutesUntilNextBooking + 5, 5);
  }

  async extend() {
    this.translocoService.selectTranslate('ride.information.extend.confirm.body', { price: this.amount * this.booking.pod?.prices?.dynamic_buffer })
      .subscribe(async (value) => {
        if (this.booking.pod.isFreeForUser) {
          this.store.dispatch(extendBooking({ amount: this.amount }));
          this.extensionDone.emit();
        } else {
          const modal = await this.modalController.create({
            component: ConfirmPage,
            componentProps: {
              confirm: {
                title: 'ride.information.extend.confirm.title',
                description: value,
                icon: 'card-outline',
                iconBgColor: 'success',
              } as Confirm
            }
          })

          modal.onDidDismiss().then(({ data }: { data: any }) => {
            if (data.action) {
              this.store.dispatch(extendBooking({ amount: this.amount }))
              this.extensionDone.emit();
            }
          });
          modal.present();
        }
      })
  }
}
